@import 'src/styles/config';
.root {
  padding: 0 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: $headerHeight;
  box-shadow: none;
  justify-content: space-between;
  border-bottom: 2px solid transparent;
  z-index: 2;

  @media screen and (max-width: $sm) {
    top: env(safe-area-inset-top);
    padding: 0 0.75rem;
    min-height: $mobileHeaderHeight;
    background-color: #000;
    border-bottom: 2px solid $borderColor;

    &::after {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: env(safe-area-inset-top);
      background-color: $brandBase1;
    }
  }
}

.logoContainer {
  display: flex;
  align-items: center;
  margin: 0;
}

.backButton {
  margin-right: 8px;
  margin-left: -8px;
}

.logo {
  cursor: pointer;
  max-height: 16px;
  display: flex;
  align-items: center;

  & > :global(svg) {
    width: 100%;
    height: 100%;
  }
}
